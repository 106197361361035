//Type for the CMSModels
//Note: Coming from response

export interface CMSModel {
  id?: number;
  slug?: string;
  name?: string;
  imageUrl?: string;
  active?: boolean;
  date?: string;
  content?: string;
  locale?: string;
  translations?: Array<{
    html?: string;
    locale?: string;
    content?: string;
    name?: string;
    imageUrl?: string;
    description?: string;
    title?: string;
    speciality?: string;
    url?: string;
    mediaFiles?: Array<{ url: string; id: number; name: string }>;
    type?: string;
    affirmations?: string;
    recommended_supplements?: string;
    benefits?: string;
    guides?: string;
  }>;
  mediaUrl?: string;
  description?: string;
  title?: string;
  speciality?: string;
  url?: string;
  videoUrl?: string;
  frequency?: number;
  category?: string;
  organ?: string;
  section?: string;
  imageTFT?: string;
  type?: string;
  videoTFT?: string;
  _classes?: string;
  benefits?: string;
  guides?: string;
  mediaFiles?: Array<{ url: string; id: number; name: string }>;
  media?: Array<{ id: string; type: string; url: string }>;
  getCategoryEndpoint?: string;
  updateCategoryEndpoint?: string;
  html?: string;
  text?: string;
}

// Note requiredFields are the fields required at the creation stage
// Display fields are fields to be displayed at the table
// Fields are the fields to be populated in the editing stage
// Populated field means that the model comes populated with entries thus create and delete actions are not available
// Common means that content field is not split into Ar Content, En Content, etc.. (locale independent)

// training categories will be added in the future. once requested uncomment it and enjoy your day
export interface Field {
  key: string;
  label?: string;
  type?: string;
  required?: boolean;
  options?: Array<string>;
  disabled?: boolean;
  multiple?: boolean;
}

export interface Model {
  [key: string]: {
    title: string;
    requiredFields: Array<Field>;
    displayFields: Array<Field>;
    fields: Array<Field>;
    postEndpoint: string;
    putEndpoint: string;
    putMethod?: "PUT" | "PATCH" | "POST";
    getEndpoint: string;
    deleteEndpoint: string;
    postEditEndpoint?: string;
    getCategoryEndpoint?: string;
    updateCategoryEndpoint?: string;
    populated?: boolean;
    common?: boolean;
  };
}
export const CMSModels: Model = {
  Training: {
    title: "Add Training",
    requiredFields: [{ key: "slug", type: "text", label: "Training Name" }],
    displayFields: [
      { key: "slug", label: "Training Name" },
      { key: "category", label: "Training Category" },
    ],
    fields: [
      { key: "slug", type: "text", required: false, label: "Training Name" },
      { key: "title", type: "text", required: true },
      { key: "url", type: "upload", required: false },
      {
        key: "lecturerName",
        label: "Lecturer Name",
        type: "text",
        required: false,
      },
      {
        key: "description",
        label: "Description",
        type: "richText",
        required: false,
      },
    ],
    postEndpoint: "training",
    putEndpoint: "training/{id}",
    putMethod: "POST",
    getEndpoint: "training",
    deleteEndpoint: "training/{id}",
    postEditEndpoint: "training/{id}",
    getCategoryEndpoint: "trainingCategory",
    updateCategoryEndpoint: "training/setCategory/{id}",
    populated: false,
    common: false,
  },
  Training_Categories: {
    title: "Add Training Category",
    requiredFields: [
      { key: "slug", type: "text", label: "Training Category Name" },
    ],
    displayFields: [{ key: "slug", label: "Training Category Name" }],
    fields: [
      {
        key: "slug",
        type: "text",
        required: false,
        label: "Training Category Name",
      },
      { key: "name", type: "text", required: true, label: "Title" },
      // {key: "imageUrl", type: "file", required: false},
    ],
    postEndpoint: "trainingCategory",
    putEndpoint: "trainingCategory/{id}",
    putMethod: "POST",
    getEndpoint: "trainingCategory",
    deleteEndpoint: "trainingCategory/{id}",
    populated: false,
    common: false,
  },

  Personal: {
    title: "Add Personal",
    requiredFields: [{ key: "slug", type: "text", label: "Personal Name" }],
    displayFields: [{ key: "slug", label: "Personal Name" }],
    fields: [
      { key: "slug", type: "text", required: false, label: "Personal Name" },
      { key: "title", type: "text", required: true },
      { key: "url", type: "upload", required: false },
    ],
    postEndpoint: "protection",
    putEndpoint: "protection/{id}",
    putMethod: "POST",
    getEndpoint: "protection",
    deleteEndpoint: "protection/{id}",
    postEditEndpoint: "protection/{id}",
    populated: false,
    common: false,
  },
  Learning: {
    title: "Add Learning",
    requiredFields: [{ key: "slug", type: "text", label: "Learning Name" }],
    displayFields: [{ key: "slug", label: "Learning Name" }],
    fields: [
      { key: "slug", type: "text", required: false, label: "Learning Name" },
      { key: "title", type: "text", required: true },
      { key: "description", type: "text", required: true },
      { key: "speciality", type: "text", required: true },
      {
        key: "type",
        type: "select",
        options: ["video", "audio"],
        required: true,
      },
      { key: "url", type: "file", required: true },
    ],
    postEndpoint: "learning",
    putEndpoint: "learning/{id}",
    putMethod: "POST",
    getEndpoint: "learning",
    deleteEndpoint: "learning/{id}",
    postEditEndpoint: "learning/{id}",
    populated: false,
    common: false,
  },
  FAQ: {
    title: "FAQ",
    requiredFields: [
      { key: "slug", type: "text", label: "Question" },
      {
        key: "isSalMagicFAQ",
        type: "select",
        options: ["No", "Yes"],
        label: "Is it a Sal Magic FAQ?",
      },
    ],
    displayFields: [
      { key: "slug", type: "text", label: "Question" },
      { key: "isSalMagicFAQ", type: "text", label: "Sal Magic FAQ" },
    ],
    fields: [
      { key: "question", type: "text", required: true, label: "Question" },
      { key: "answer", type: "textArea", required: true, label: "Answer" },
    ],
    postEndpoint: "faq",
    putEndpoint: "faq/{id}",
    putMethod: "POST",
    getEndpoint: "faq",
    deleteEndpoint: "faq/{id}",
    postEditEndpoint: "faq/{id}",
    populated: false,
    common: false,
  },
  Healing: {
    title: "Add Disease",
    requiredFields: [
      { key: "gender", type: "radio_btn" },
      { key: "section", type: "select" },
      { key: "organ", type: "select" },
      { key: "slug", type: "text", label: "Healing Name" },
    ],
    displayFields: [{ key: "slug", label: "Healing Name" }, { key: "organ" }],
    fields: [
      { key: "slug", type: "text", required: false, label: "Healing Name" },
      { key: "name", type: "text", required: true },
      { key: "description", type: "text", required: true },
      { key: "affirmations", type: "add", required: true },
      {
        label: "Recommended Supplements",
        key: "recommended_supplements",
        type: "richText",
        required: false,
      },
    ],
    postEndpoint: "healing",
    putEndpoint: "healing/{id}",
    putMethod: "POST",
    getEndpoint: "healing",
    deleteEndpoint: "healing/{id}",
    populated: false,
    common: false,
  },
  TFT: {
    title: "Add TFT",
    requiredFields: [
      { key: "imageUrl", type: "file" },
      { key: "videoUrl", type: "file" },
    ],
    displayFields: [{ key: "name" }],
    fields: [
      { key: "imageTFT", type: "file", required: false },
      { key: "videoTFT", type: "file", required: false },
      { key: "article", type: "add", required: true },
    ],
    postEndpoint: "tft",
    putEndpoint: "tft/{id}",
    putMethod: "PUT",
    getEndpoint: "tft",
    deleteEndpoint: "tft/{id}",
    populated: true,
    common: false,
  },
  Chakra: {
    title: "Add Chakra",
    requiredFields: [
      { key: "slug", type: "text", label: "Chakra Name" },
      { key: "imageUrl", type: "file" },
      { key: "videoUrl", type: "upload" },
      { key: "audioUrl", type: "file", required: false, label: "Audio" },
      { key: "gifUrl", type: "file", label: "Audio Gif", required: false },
    ],
    displayFields: [{ key: "slug", label: "Chakra Name" }],
    fields: [
      {
        key: "slug",
        type: "text",
        required: false,
        label: "Chakra Name",
        disabled: true,
      },
      { key: "imageUrl", type: "file", required: false },
      { key: "videoUrl", type: "upload", required: false },
      { key: "audioUrl", type: "file", required: false, label: "Audio" },
      { key: "gifUrl", type: "file", label: "Audio Gif", required: false },
      { key: "name", type: "text", required: true },
    ],
    postEndpoint: "chakra",
    putEndpoint: "chakra/{id}",
    putMethod: "POST",
    getEndpoint: "chakra",
    deleteEndpoint: "chakra/{id}",
    postEditEndpoint: "training/{id}",
    populated: true,
    common: false,
  },
  Solfeggio: {
    title: "Add Solfeggio",
    requiredFields: [
      { key: "videoUrl", type: "file" },
      { key: "audioUrl", type: "file" },
      { key: "gifUrl", type: "file" },
    ],
    displayFields: [{ key: "frequency" }],
    fields: [
      { key: "videoUrl", type: "upload", required: false },
      { key: "audioUrl", type: "file", label: "Audio", required: false },
      { key: "gifUrl", type: "file", label: "Audio Gif", required: false },
    ],
    postEndpoint: "solfeggio",
    putEndpoint: "solfeggio/{id}",
    putMethod: "PUT",
    getEndpoint: "solfeggio",
    deleteEndpoint: "solfeggio/{id}",
    populated: true,
    common: false,
  },
  Waves: {
    title: "Add Wave",
    requiredFields: [{ key: "slug", type: "text", label: "Wave Name" }],
    displayFields: [
      { key: "slug", label: "Wave Name" },
      { key: "category", label: "Wave Category" },
    ],
    fields: [
      { key: "slug", type: "text", required: false, label: "Wave Name" },
      { key: "name", type: "text", required: true, label: " Title" },
      { key: "active", type: "checkbox", required: false },
      { key: "imageUrl", type: "file", required: false },
      { key: "mediaFiles", type: "file", required: false, multiple: true },
      { key: "richText", type: "richText", required: true },
    ],
    postEndpoint: "wave",
    putEndpoint: "wave/{id}",
    putMethod: "POST",
    getEndpoint: "wave",
    deleteEndpoint: "wave/{id}",
    getCategoryEndpoint: "waveCategory",
    updateCategoryEndpoint: "wave/setCategory/{id}",
    populated: false,
    common: false,
  },
  Waves_Categories: {
    title: "Add Wave Category",
    requiredFields: [
      { key: "slug", type: "text", label: "Wave Category Name" },
    ],
    displayFields: [{ key: "slug", label: "Wave Category Name" }],
    fields: [
      {
        key: "slug",
        type: "text",
        required: false,
        label: "Wave Category Name",
      },
      { key: "name", type: "text", required: true, label: "Title" },
    ],
    postEndpoint: "waveCategory",
    putEndpoint: "waveCategory/{id}",
    putMethod: "POST",
    getEndpoint: "waveCategory",
    deleteEndpoint: "waveCategory/{id}",
    populated: false,
    common: false,
  },
  Balance: {
    title: "Add Balance",
    requiredFields: [{ key: "url", type: "file" }],
    displayFields: [{ key: "url" }],
    fields: [
      { key: "url", type: "file", required: false },
      { key: "name", type: "text", required: true },
    ],
    postEndpoint: "balance",
    putEndpoint: "balance/{id}",
    putMethod: "PUT",
    getEndpoint: "balance",
    deleteEndpoint: "balance/{id}",
    populated: false,
    common: false,
  },
  Manifestation: {
    title: "Add Manifestation",
    requiredFields: [{ key: "name", type: "text" }],
    displayFields: [{ key: "name" }],
    fields: [
      { key: "mediaUrl", type: "file", required: false },
      { key: "name", type: "text", required: true },
      { key: "description", type: "textArea", required: true },
    ],
    postEndpoint: "manifestation",
    putEndpoint: "manifestation/{id}",
    putMethod: "POST",
    getEndpoint: "manifestation",
    deleteEndpoint: "manifestation/{id}",
    populated: false,
    common: false,
  },
  Subliminal: {
    title: "Add Subliminal",
    requiredFields: [
      {
        key: "category",
        type: "select",
        options: ["Video", "Audio"],
        required: true,
      },
      { key: "name", type: "text" },
      { key: "imageUrl", type: "file", required: false },
    ],
    displayFields: [{ key: "name" }],
    fields: [
      { key: "name", type: "text", required: true },
      { key: "url", type: "file", label: "Media File", required: false },
      { key: "richText", type: "richText", required: true },
    ],
    postEndpoint: "subliminal/static",
    putEndpoint: "subliminal/static/{id}",
    putMethod: "POST",
    getEndpoint: "subliminal/static",
    deleteEndpoint: "subliminal/static/{id}",
    populated: false,
    common: false,
  },
  Main_Sections: {
    title: "Add content to main sections",
    requiredFields: [{ key: "videoUrl", type: "file" }],
    displayFields: [{ key: "slug", label: "Main Section", type: "textArea" }],
    fields: [{ key: "content", type: "text", required: true }],
    postEndpoint: "description",
    putEndpoint: "description/{slug}",
    putMethod: "PUT",
    getEndpoint: "description",
    deleteEndpoint: "description/{id}",
    populated: true,
    common: false,
  },
  Pure_Tones: {
    title: "Add Pure Tone",
    requiredFields: [
      { key: "slug", type: "text", label: "Pure Tone Name" },
      { key: "url", type: "file", required: false, label: "Pure Tone File" },
      { key: "imageUrl", type: "file", label: "Cover Image" },
    ],
    displayFields: [
      { key: "slug", label: "Pure Tone Name" },
      { key: "category", label: "Category" },
    ],
    fields: [
      { key: "name", type: "text", required: false, label: "Pure Tone Name" },
      { key: "description", type: "richText", label: "description" },
    ],
    postEndpoint: "pure-tone",
    putEndpoint: "pure-tone/{id}",
    putMethod: "POST",
    getEndpoint: "pure-tone",
    deleteEndpoint: "pure-tone/{id}",
    getCategoryEndpoint: "pure-tone/category",
    updateCategoryEndpoint: "pure-tone/setCategory/{id}",
    populated: false,
    common: false,
  },
  Other_Sources: {
    title: "Add Other Source",
    requiredFields: [
      { key: "slug", type: "text", label: "Name" },
      { key: "url", type: "file", label: "Track" },
      { key: "imageUrl", type: "file", label: "Thumbnail" },
    ],
    displayFields: [
      { key: "slug", label: "Name" },
      { key: "category", label: "Category" },
    ],
    fields: [
      { key: "name", type: "text", required: false, label: "Name" },
      { key: "description", type: "richText", label: "description" },
    ],
    postEndpoint: "other-source",
    putEndpoint: "other-source/{id}",
    putMethod: "POST",
    getEndpoint: "other-source",
    deleteEndpoint: "other-source/{id}",
    getCategoryEndpoint: "pure-tone/category",
    updateCategoryEndpoint: "other-source/setCategory/{id}",
    populated: false,
    common: false,
  },
  "Pure Tones Categories": {
    title: "Add Pure Tones Category",
    requiredFields: [
      { key: "slug", type: "text", label: "Pure Tones Category Name" },
    ],
    displayFields: [{ key: "slug", label: "Pure Tones Category Name" }],
    fields: [
      {
        key: "name",
        type: "text",
        label: "Pure Tone Category Name",
      },
    ],
    postEndpoint: "pure-tone/category",
    putEndpoint: "pure-tone/category/{id}",
    putMethod: "POST",
    getEndpoint: "pure-tone/category",
    deleteEndpoint: "pure-tone/category/{id}",
    populated: false,
    common: false,
  },
};
